<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { authComputed } from "@/state/helpers";
import { roleService } from "@/app/service/roleService";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Preloader from "@/components/preloader";
import internationalApplicantDataStep from "./steps/international-applicant-data-step";
import internationalCompanyDataStep from "./steps/international-company-data-step";
import internationalProviderDataStep from "./steps/international-provider-data-step";
import internationalTransferDataStep from "./steps/international-transfer-data-step";
import internationalSummaryDataStep from "./steps/international-summary-data-step";
import TransferInfoSidebar from "@/components/transfer/transfer-info-sidebar";
import Repository from "@/app/repository/repository-factory";
import Vue from "vue";

const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);

/**
 * Przelew zagraniczny component
 */
export default {
  page: {
    title: "Przelew zagraniczny",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    FormWizard,
    TabContent,
    internationalApplicantDataStep,
    internationalCompanyDataStep,
    internationalProviderDataStep,
    internationalTransferDataStep,
    internationalSummaryDataStep,
    TransferInfoSidebar,
  },
  data() {
    return {
      preloader: true,
      disabledBtn: false,
      clonedData: null,
      loggedUser: null,
      wizardError: false,
      finalModel: {
        applicantUserAccountId: "",
        applicantFirstNameField: "",
        applicantLastNameField: "",
        applicantEmailField: "",
        companyId: "",
        companyNameField: "",
        companyAddressField: "",
        companyTaxNumberField: "",
        companyAccountSelectValueField: {
          index: null,
          name: "",
          description: "",
        },
        companyBusinessUnitCode: "",
        providerSapId: "",
        providerNameField: "",
        providerAddressField: "",
        providerAddressCity: "",
        providerAddressStreet: "",
        providerAddressHouseNo: "",
        providerAddressFlatNo: "",
        providerAddressPostalCode: "",
        providerAddressInternational: "",
        providerTaxNumberField: "",
        providerAccountField: "",
        providerIbanField: "",
        providerBankNameField: "",
        // providerBankCodeField: "",
        providerCountryValueField: "",
        providerCountryBankValueField: "",
        providerBicSwiftField: "",
        providerFormDataModified: false,
        providerWithoutTaxNumber: true,
        transferTitleField: "",
        transferGrossAmountField: null,
        transferCurrencySelectField: "",
        transferDateOfOperationField: "",
        transferTaxOpinionField: null,
        transferFeesAndChargesSelectField: "",
        transferApproverSelectField: {
          index: null,
          userAccountId: "",
          isActive: false,
          name: "",
          company: "",
          email: "",
          phone: "",
          registered: "",
        },
        transferHigherApproverSelectField: {
          index: null,
          userAccountId: null,
          isActive: false,
          name: "",
          company: "",
          email: "",
          phone: "",
          registered: "",
        },
        transferAdditionalInfoField: "",
        transferRequestForConfirmationField: null,
        transferAttachmentsField: [],
      },
      title: "Przelew zagraniczny",
      items: [
        {
          text: "Strona główna",
          to: { name: "home" },
        },
        {
          text: "Przelew zagraniczny",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loggedUser = this.currentUser();
    if (localStorage.getItem("cloneApplicationAction") !== null) {
      this.clonedData = JSON.parse(
        localStorage.getItem("cloneApplicationAction")
      );
      localStorage.removeItem("cloneApplicationAction");
    }
    setTimeout(() => {
      this.preloader = false;
    }, 1000);
  },
  methods: {
    ...authComputed,
    validateStep(name) {
      return this.$refs[name].validate();
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        this.wizardError = false;
        this.finalModel = Object.assign({}, this.finalModel, model);
      } else {
        this.wizardError = true;
      }
    },
    onComplete: function () {
      this.disabledBtn = true;
      this.preloader = true;

      let applicationData = {
        typeOfApplication: 2,
        providerSapId: this.finalModel.providerSapId,
        providerName: this.finalModel.providerNameField,
        // providerAddress: this.finalModel.providerAddressField,

        providerAddressCity: this.finalModel.providerAddressCity,
        providerAddressStreet: this.finalModel.providerAddressStreet,
        providerAddressHouseNo: this.finalModel.providerAddressHouseNo,
        providerAddressFlatNo: this.finalModel.providerAddressFlatNo,
        providerAddressPostalCode: this.finalModel.providerAddressPostalCode,
        providerAddressInternational: this.finalModel.providerAddressInternational,

        providerNip: this.finalModel.providerTaxNumberField == '' ? null : this.finalModel.providerTaxNumberField,
        providerBankAccountNumber: this.finalModel.providerAccountField,
        providerIban: this.finalModel.providerIbanField,
        providerSwiftCode: this.finalModel.providerBicSwiftField,
        providerBankName: this.finalModel.providerBankNameField,
        // providerBankCode: this.finalModel.providerBankCodeField,
        providerCountry: this.finalModel.providerCountryValueField.code,
        providerBankCountry: this.finalModel.providerCountryBankValueField.code,
        changeDataManually: this.finalModel.providerFormDataModified,
        providerWithoutTaxNumber: this.finalModel.providerWithoutTaxNumber,
        applicantUserAccountId: this.finalModel.applicantUserAccountId,
        acceptorUserAccountId: this.finalModel.transferApproverSelectField.userAccountId,
        higherAcceptorUserAccountId: this.finalModel.transferHigherApproverSelectField.userAccountId,
        transfer: this.finalModel.transferTitleField,
        companyId: this.finalModel.companyId,
        invoiceNumber: this.finalModel.transferInvoiceNumberField,
        grossAmount: this.finalModel.transferGrossAmountField,
        vatAmount: this.finalModel.transferVatAmountField,
        dateOfOperation: this.finalModel.transferDateOfOperationField,
        typeOfBankAccount: this.finalModel.companyAccountSelectValueField.index,
        additionalInfo: this.finalModel.transferAdditionalInfoField,
        confirmTransfer: this.finalModel.transferRequestForConfirmationField,
        taxOpinion: this.finalModel.transferTaxOpinionField,
        feesAndCharges: this.finalModel.transferFeesAndChargesSelectField.index,
        currency: this.finalModel.transferCurrencySelectField.id,
        attachments: this.finalModel.transferAttachmentsField,
      };
      PaymentApplicationRepository.create(applicationData)
        .then((response) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES! ",
            text: "Wniosek przelewu zagranicznego został wysłany",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({ name: "home" });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            toast: false,
            position: "top",
            title: "Błąd! ",
            text:
              "Nie można złożyć wniosku o przelew zagraniczny: " +
              error.response.data.message,
            showConfirmButton: false,
            timer: 3500,
            // onClose: () => {
            //   this.$router.push({ name: "home"})
            // }
          });
          console.log(error);
        });
    },
  },
  computed: {
    isApplicant() {
      return roleService.isApplicant();
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="isApplicant">
      <div class="col-md-8">
        <div class="card shadow">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div class="media">
                  <img class="mr-3 mt-1" src="@/assets/images/si-pay-logo.png" alt="logo" height="30" />
                  <div class="media-body">
                    <h5 class="my-0">Wnioskujący : {{ loggedUser.firstName }} {{ loggedUser.lastName }}</h5>
                    <p class="small m-0 p-0">{{ loggedUser.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <b-link class="btn btn-sm btn-warning" :to="{ name: 'home' }">Anuluj wniosek</b-link>
              </div>
            </div>
            <hr />
            <b-alert v-if="wizardError" variant="danger" class="mb-3" show>
              <strong>Błąd!</strong> Coś poszło nie tak, uzupełnij formularz poprawnie i spróbuj ponownie.
            </b-alert>
            <form-wizard shape="circle" color="#556ee6" errorColor="#ffffff" back-button-text="Powrót" next-button-text="Dalej" finish-button-text="Wyślij wniosek" @on-complete="onComplete">
              <!-- step 1 -->
              <!-- applicant -->
              <tab-content title="Wnioskujący" route="/international-transfer/applicant" :before-change="()=>validateStep('internationalApplicantDataStep')">
                <internationalApplicantDataStep ref="internationalApplicantDataStep" @on-validate="mergePartialModels"></internationalApplicantDataStep>
              </tab-content>
              <!-- step 2 -->
              <!-- company -->
              <tab-content title="Dane firmy" route="/international-transfer/company" :before-change="()=>validateStep('internationalCompanyDataStep')">
                <internationalCompanyDataStep ref="internationalCompanyDataStep" @on-validate="mergePartialModels" :clonedData="clonedData"></internationalCompanyDataStep>
              </tab-content>
              <!-- step 3 -->
              <!-- provider -->
              <tab-content title="Dostawca" route="/international-transfer/provider" :before-change="()=>validateStep('internationalProviderDataStep')">
                <internationalProviderDataStep ref="internationalProviderDataStep" @on-validate="mergePartialModels" :bucAsUuid="finalModel.companyId" :clonedData="clonedData"></internationalProviderDataStep>
              </tab-content>
              <!-- step 4 -->
              <!-- transfer -->
              <tab-content title="Dane do przelewu" route="/international-transfer/transfer" :before-change="()=>validateStep('internationalTransferDataStep')">
                <internationalTransferDataStep ref="internationalTransferDataStep" @on-validate="mergePartialModels" :companyId="finalModel.companyId" :providerFormDataModified="this.finalModel.providerFormDataModified" :clonedData="clonedData"></internationalTransferDataStep>
              </tab-content>
              <!-- step 5 -->
              <!-- summary -->
              <tab-content title="Podsumowanie" route="/international-transfer/summary">
                <internationalSummaryDataStep ref="internationalSummaryDataStep" :summaryData="finalModel"></internationalSummaryDataStep>
              </tab-content>
              <router-view></router-view>
            </form-wizard>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow">
          <TransferInfoSidebar :finalModel="finalModel" :loggedUser="loggedUser" />
        </div>
      </div>
    </div>
  </Layout>
</template>
