//src\Payment\Domain\ValueObject\Currency.php
const currencyListData = [
  {
    "index": 0,
    "id": 'PLN',
    "name": 'Polski Złoty',
  },
  {
    "index": 1,
    "id": 'EUR',
    "name": 'Euro',
  },
  {
    "index": 2,
    "id": 'USD',
    "name": 'Dolar Amerykański',
  },
  {
    "index": 3,
    "id": 'GBP',
    "name": 'Funt Brytyjski',
  },
  {
    "index": 4,
    "id": 'CHF',
    "name": 'Frank Szwajcarski',
  },
  {
    "index": 5,
    "id": 'CAD',
    "name": 'Dolar Kanadyjski',
  },
  {
    "index": 6,
    "id": 'JPY',
    "name": 'Jen Japoński',
  },
  {
    "index": 7,
    "id": 'AUD',
    "name": 'Dolar Australijski',
  },
  {
    "index": 8,
    "id": 'CZK',
    "name": 'Korona Czeska',
  },
  {
    "index": 9,
    "id": 'HRK',
    "name": 'Kuna Chorwacka',
  },
  {
    "index": 10,
    "id": 'DKK',
    "name": 'Korona Duńska',
  },
  {
    "index": 11,
    "id": 'NOK',
    "name": 'Korona Norweska',
  },
  {
    "index": 12,
    "id": 'RUB',
    "name": 'Rubel Rosyjski',
  },
  {
    "index": 13,
    "id": 'SEK',
    "name": 'Korona Szwedzka',
  },
  {
    "index": 14,
    "id": 'HUF',
    "name": 'Forint Węgierski',
  },
  {
    "index": 15,
    "id": 'AED',
    "name": 'Dirham',
  },
  {
    "index": 16,
    "id": 'KZT',
    "name": 'Tenge',
  },

]
export { currencyListData };
