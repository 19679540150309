//
// Słownik
// lista wyboru
// VO

const feesAndChargesData = [
  {
    "index": 1,
    "name": "zleceniodawcy",
    "description": "",
  },
  {
    "index": 2,
    "name": "dzielone",
    "description": "",
  },
  {
    "index": 3,
    "name": "odbiorcy",
    "description": "",
  },
];

export { feesAndChargesData };
